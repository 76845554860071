import kkk from './images/kkk.jpg';
import gabriel from './images/gabriel.jpg';
import gerardo from './images/gerardo.jpg';
import ricardo from './images/ricardo.jpg';

export const images = {
  kkk,
  gabriel,
  gerardo,
  ricardo,
};
